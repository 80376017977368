import { Button, H3, Intent, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { connect } from 'react-redux';
import { restart } from '../actions/actions';
import CheckoutsList from './checkoutsList';

const ChooseCheckout = ({ loadingCheckouts, restart, entries }) => {
  if (loadingCheckouts) {
    return <Spinner />;
  }

  return (
    <div style={{ display: 'flex' }}>
      <div className='choose-checkout'>
        <H3>Choose from the games you have checked out:</H3>
        <CheckoutsList />
        <div className='button-group w100'>
          <Button
            intent={Intent.DANGER}
            className='back-button'
            text='Back'
            icon={IconNames.ARROW_LEFT}
            onClick={restart}
          />
          <div>&nbsp;</div>
        </div>
      </div>
      <div style={{marginLeft: '20px', borderLeft: '1px'}}>
        <H3>Already entered:</H3>
        <div>
          {entries.length > 0 && 
            entries.map((title, index) => (
              <p key={index}>{title}</p>
            ))
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  loadingCheckouts: state.checkoutsList.loading,
  entries: state.checkoutsList.entries,
});
const mapDispatchToProps = dispatch => ({ restart: () => dispatch(restart()) });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseCheckout);
