import * as types from '../actions/actionTypes';
import { incrementStep } from './actions';

export function fetchCheckouts() {
  return (dispatch, getState) => {
    dispatch({ type: types.GetCheckoutsRequest });

    const { user } = getState();

    return fetch(`${API_URL}/checkouts/?badgeId=${user.badgeId}`)
      .then(response => response.json())
      .then(data => {
        dispatch({ type: types.GetCheckoutsReceive, payload: data });
      })
      .catch(error => {
        dispatch({ type: types.GetCheckoutsFailure, error: error.message });
      });
  };
}

export const selectCheckout = checkout => ({
  type: types.CheckoutSelected,
  checkout
});
