import * as types from '../actions/actionTypes';

const checkouts = (state = { checkouts: [], error: null, loading: false }, action) => {
  let error = null;
  switch (action.type) {
  case types.GetCheckoutsRequest:
    return { ...state, checkouts: [], error: null, loading: true };
  case types.GetCheckoutsReceive: {
    var checkouts = [];
    if(action.payload.result)
    {
      checkouts = action.payload.result
        .filter(checkout => checkout.copy.collection.allowWinning)
        .map(checkout => ({
          id: checkout.id,
          game: checkout.copy.game.name,
          checkoutOwner: { id: checkout.attendee.id, name: checkout.attendee.name, badgeNumber: checkout.attendee.badgeNumber, rating: null, wantsToWin: true }
        }));
    }
    error =
        !checkouts || !checkouts.length
          ? { title: 'No Eligible Checkouts Found', message: 'Ensure that you entered the correct badge number.' }
          : null;
    if(action.payload.errors.length > 0)
    {
      error = { title: 'Badge Type Error', message: action.payload.errors[0] };
    }
    return { ...state, checkouts, entries: action.payload.entries, error, loading: false };
  }
  case types.GetCheckoutsFailure:
    return {
      ...state,
      error: {
        title: 'Error occurred',
        message:
            'An error occurred while trying to fetch the checkouts. Try again. If this problem persists, let someone know.'
      },
      loading: false
    };

  case types.EnterAnotherPlay:
    return { ...state, checkouts: [], loading: true };
  default:
    return state;
  }
};

export default checkouts;
