import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Spinner, Intent, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Popover2 } from '@blueprintjs/popover2';
import WrongBadgeNumberWarning from '../sharedComponents/wrongBadgeNumberWarning';
import LabeledInput from '../sharedComponents/labeledInput';
import { updatebadgeId, incrementStep } from '../actions/actions';
import { fetchCheckouts } from '../actions/checkouts';
import PlayerSearchInput from '../enterPlayInfo/playerSearchInput';
import { updatePlayerSearchQuery } from '../actions/players';

const focusPlayerSearchInput = () => {
  document.getElementById('player-search').focus();
};

const updatebadgeNumber = (badgeNumber) => {
  document.getElementById('badge-number').value = badgeNumber;
  document.getElementById('badge-number').focus();
};

const AuthenticatePresentation = ({
  badgeId,
  fetchCheckouts,
  updatebadgeId,
  nextButtonDisabled,
  loadingCheckouts,
  noCheckoutsFound,
  query, 
  loading, 
  foundAttendees,
  updateQuery
}) => {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        fetchCheckouts();
      }}
    >
      <p>Fill in your badge number to begin entering a new Play!</p>
      {noCheckoutsFound ? <WrongBadgeNumberWarning /> : ''}

      <div className='form-section authenticate-form-section'>
        <Popover2
          minimal
          usePortal={false}
          inline={true}
          className='player-search-popover'
          autoFocus={false}
          position={Position.TOP}
          popoverDidOpen={focusPlayerSearchInput}
          onClose={() => { document.getElementById('player-search').value = '';} }
          isOpen={isOpen}
          renderTarget={({ isOpen, ref, ...targetProps }) => (
            <Button
              {...targetProps}
              elementRef={ref}
              text='Name Search'
              intent={Intent.PRIMARY}
              rightIcon={IconNames.PRIMARY}
              large={true}
              className='add-player-button'
              onClick={() => setIsOpen(!isOpen)}
            />    
          )}
          content={
            <div className='player-search-popover-content' id='search-popover'>
              <PlayerSearchInput center={true} />
              {!foundAttendees.length && query && !loading ? <b>No people found. Search by name or badge number.</b> : ''}
              <div className='player-search-results'>
                <div className='player-search-results-rows'>
                  {foundAttendees.map(attendee => (
                    <Button
                      key={attendee.id}
                      intent={Intent.SUCCESS}
                      className='player-search-results-row player-search-results-add'
                      onClick={() => {
                        updatebadgeId(attendee.badgeNumber);
                        updatebadgeNumber(attendee.badgeNumber);
                        updateQuery('');
                        setIsOpen(!isOpen);
                        fetchCheckouts();
                      }}
                      text={`${attendee.name} (${attendee.badgeNumber})`}
                    />
                  ))}
                </div>
                {loading ? <Spinner /> : ''}
              </div>
            </div>
          }
        />
        <div style={{ marginTop: '20px' }} />
        <LabeledInput
          label='Badge #'
          inputId='badge-number'
          autoFocus={true}
          placeholder='Your Badge Number'
          value={badgeId}
          onChange={newText => updatebadgeId(newText)}
        />
        {loadingCheckouts ? (
          <Spinner />
        ) : (
          <Button
            rightIcon={IconNames.ARROW_RIGHT}
            large={true}
            intent={Intent.SUCCESS}
            text='Next'
            disabled={nextButtonDisabled}
            onClick={fetchCheckouts}
          />
        )}
      </div>
    </form>
  )
      };

const mapStateToProps = state => {
  const currentPlayers = state.play.players;
  const searchResults = state.playerSearch.results;
  const sortedAttendees = _.orderBy(searchResults, ['name']);
  const top10Attendees = _.slice(sortedAttendees, 0, 10);
  const filteredAttendees = _.differenceBy(top10Attendees, currentPlayers, a => a.id);
  
  return {
    nextButtonDisabled: !state.user.badgeId || state.checkoutsList.loading,
    loadingCheckouts: state.checkoutsList.loading,
    badgeId: state.user.badgeId,
    noCheckoutsFound: state.checkoutsList.error,
    query: state.playerSearch.query,
    loading: state.playerSearch.loading,
    foundAttendees: filteredAttendees
  }
};

const mapDispatchToProps = dispatch => ({
  fetchCheckouts: () => dispatch(fetchCheckouts()),
  incrementStep: () => dispatch(incrementStep()),
  updatebadgeId: badgeId => dispatch(updatebadgeId(badgeId)),
  updateQuery: query => dispatch(updatePlayerSearchQuery(query))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticatePresentation);
